import ResourceTableDialog from "../resource-table-dialog";
import {useDispatch, useSelector} from "react-redux";
import Resources from "../../../../data/services/resources";
import {Field, FieldsManager} from "../../../../data/services/fields";
import Button from "../../button";
import {checkPerm} from "../../../../util/util";
import {CREATE_PERM, DELETE_PERM, UPDATE_PERM} from "../../../util/util-consts";
import React, {useState} from "react";
import useQuery from "../../../../hooks/use-query";
import {
    createDialogResource,
    deleteDialogResource,
    updateDialogResource
} from "../../../../data/actions/dialogResource";
import LocalStorage from "../../../../util/localStorage";
import ModalSaveResource from "../modal-save-resource";
import ModalConfirm from "../modal-confirm";
import {
    getPartManufacturerFields,
    getPartManufacturerQueryFields
} from "../../../../views/parts-view/manufacturers-tab";
import {deleteResource} from "../../../../data/actions/resource";

export default function PartManufacturersResourceList({
                                                          isLocationsListModalOpen,
                                                          onCloseLocationListModal,
                                                          onSelectItem,
                                                          translate
                                                      }) {
    const resourcePath = Resources.WorkOrderProductsManufacturers;

    const dispatch = useDispatch();

    const dialogResource = useSelector(state => state.dialogResource);

    const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
    const [isPartCategoryModalOpen, setIsPartCategoryModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [partsPartManufacturerFields] = useQuery(getPartManufacturerQueryFields(), 'part-Manufacturers-tab')
    const partManufacturerQuery = FieldsManager.getFieldKeyValues(partsPartManufacturerFields);

    function handleCreateManufacturerClick(selectedItem = {}) {
        setIsPartCategoryModalOpen(true);

        if (!!selectedItem?.Manufacturer) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }

        setSelectedItem(selectedItem)
    }

    function handleEditManufacturerClick(item) {
        setIsPartCategoryModalOpen(true);
        setSelectedItem(item)
    }

    function handleDeleteManufacturerClick(item) {
        setIsDeleteConfirmModalOpen(true);
        setSelectedItem(item);
    }

    function handleConfirmDeleteClick() {
        dispatch(deleteDialogResource({
            user: LocalStorage.get('user'),
            query: {
                ManufacturerID: selectedItem.ManufacturerID
            },
            piggyQuery: partManufacturerQuery,
            errorMessage: true,
            successMessage: translate(`message.archived_generic`, [selectedItem.Manufacturer]),
            resource: resourcePath,
            piggyResource: resourcePath
        }));

        setIsDeleteConfirmModalOpen(false);
    }

    return (
        <>
            <ResourceTableDialog
                show={isLocationsListModalOpen}
                dialogResource={dialogResource}
                resource={resourcePath}
                title={translate("text.select_part_manufacturer")}
                noRecordsText={() => translate('text.no_matching_records')}
                noRecordsBtnLabel={(currentQuery) => translate('btn.create_part_manufacturer_name', [currentQuery])}
                noRecordsBtnClick={currentQuery => handleCreateManufacturerClick({"Manufacturer": currentQuery})}
                dispatch={dispatch}
                fields={getPartManufacturerFields()}

                widthClass={"max-w-7xl"}
                options={{
                    style: {
                        stripedRows: true,
                        horizontalLines: false,
                        floatingActions: true,
                    },
                }}
                fieldsFilter={{
                    query: new Field('query', '', [''], false, 'search', {
                        addContainerClass: 'col-span-3',
                        labelType: "float"
                    })
                }}
                tableHeaderRight={
                    <div className="col-start-11 col-span-2 flex justify-end items-center">
                        <Button
                            hasPerm={checkPerm(resourcePath, CREATE_PERM)}
                            className="btn btn-primary"
                            onClick={() => handleCreateManufacturerClick(null)}
                        >
                            {translate("btn.create_part_manufacturer")}
                        </Button>
                    </div>
                }
                sortBy={"Manufacturer"}
                defaultAction={(item) => onSelectItem(item)}
                onEdit={(item) => handleEditManufacturerClick(item)}
                hasEditPerm={checkPerm(resourcePath, UPDATE_PERM)}
                onDelete={(item) => handleDeleteManufacturerClick(item)}
                hasDeletePerm={checkPerm(resourcePath, DELETE_PERM)}
                onClose={onCloseLocationListModal}
                translate={translate}
                htmlAfter={
                    <>
                        <ModalSaveResource
                            title={translate(`text.${selectedItem?.ManufacturerID ? 'Edit' : 'CreateNew'}PartManufacturer`)}
                            show={isPartCategoryModalOpen}
                            widthClass="max-w-md"
                            gridColsClass="grid-cols-3"
                            translate={translate}
                            canSubmit={!isButtonDisabled}
                            fields={getPartManufacturerFields(selectedItem)}
                            onClose={() => setIsPartCategoryModalOpen(false)}
                            onSubmit={(params) => {
                                if (params) {
                                    console.log("selectedItem", selectedItem)
                                    if (selectedItem) {
                                        dispatch(updateDialogResource({
                                            user: LocalStorage.get('user'),
                                            params: Object.assign(params, {
                                                ManufacturerID: selectedItem.ManufacturerID
                                            }),
                                            errorMessage: true, successMessage: translate("message.manufacturer_updated"),
                                            resource: resourcePath,
                                            piggyResource: resourcePath
                                        }))
                                    } else {
                                        dispatch(createDialogResource({
                                            user: LocalStorage.get('user'),
                                            params: Object.assign(params, {
                                                query: partManufacturerQuery
                                            }),
                                            errorMessage: true, successMessage: translate("message.manufacturer_created"),
                                            resource: resourcePath,
                                            piggyResource: resourcePath
                                        }))
                                    }

                                    setIsPartCategoryModalOpen(false);
                                }
                            }}
                        />

                        <ModalConfirm
                            title={translate("text.Confirm")}
                            show={isDeleteConfirmModalOpen}
                            text={translate("text.delete_part_manufacturer")}
                            onClose={() => {
                                setIsDeleteConfirmModalOpen(false);
                            }}
                            buttonLabel={translate("btn.confirm")}
                            closeButtonLabel={'Cancel'}
                            translate={translate}
                            onConfirm={handleConfirmDeleteClick}
                        />
                    </>
                }
            />
        </>
    )
}