import React, {useEffect, useState} from "react";
import {Field, FieldsManager} from "../../../data/services/fields";
import {getDefaultQueryFields, getUser, updateQuery} from "../../../util/util";
import {useDispatch, useSelector} from "react-redux";
import {classNames, getProp} from "../../../common/util/util-helpers";
import Resources from "../../../data/services/resources";
import {createResource, deleteResource, getResource, updateResource} from "../../../data/actions/resource";
import useQuery from "../../../hooks/use-query";
import LocalStorage from "../../../util/localStorage";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import Layout from "../../../common/components/layout";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTableTopBar from "../../../common/components/resource-table/table-components/resource-table-top-bar";
import FieldSearch from "../../../common/components/fields/field-text/search";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Tooltip from "../../../common/components/tooltip";
import ResourceTable from "../../../common/components/resource-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import NoRecords from "../../../common/components/no-records-found/no-records";
import ModalSaveResourceAuto from "../../../common/components/modal/modal-save-resource/modal-save-resource-auto";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import Page from "../../../common/components/layout/layout-components/page";

export default function RepairCodesView({history, match, translate}) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const isLoading = getProp(resource, "isLoading", true);
    const count = getProp(resource.data, "count", 0)
    const data = getProp(resource.data, "list", [])

    /** State
     ================================================================= */
    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath())
    const query = FieldsManager.getFieldKeyValues(queryFields);

    const [isResourceDialogOpen, setIsResourceDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const [confirmDialogData, setConfirmDialogData] = useState({});
    const [isDefaultDialogStateDirty, setIsDefaultDialogStateDirty] = useState(false);

    /** Data events
     ================================================================= */
    const handleConfirmDeleteResourceClick = (item) => {
        dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: Object.assign(query, {CustomCodeID: item.CustomCodeID}),
            successMessage: translate(`text.custom_code_deleted`),
            resource: getResourcePath(),
            piggyResource: getResourcePath()
        }));

        closeConfirmDialog();
    }

    const handleConfirmRestoreResourceClick = (item) => {
        dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {CustomCodeID: item.CustomCodeID, ArchivedDate: 1},
            query: query,
            successMessage: translate(`text.custom_code_restored`),
            resource: getResourcePath(),
            piggyResource: getResourcePath()
        }));

        closeConfirmDialog();
    }

    /** UI Events
     ================================================================= */
    const handleQueryChange = (name, value) => {
        let queryUpdate = updateQuery(queryFields, name, value);
        if (name === 'query') {
            queryUpdate.offset.value = "0"
        }

        if (name === 'limit') {
            queryUpdate.offset.value = "0"
        }

        setQueryFields(queryUpdate);
        fetchData(dispatch, FieldsManager.getFieldKeyValues(queryUpdate));
    }

    const handleCreateResourceClick = (e, preFill) => {
        setSelectedItem({CustomCode: preFill});
        setIsDefaultDialogStateDirty(!!preFill);
        setIsResourceDialogOpen(true);
    }

    const handleDeleteResourceClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmDeleteResourceClick(item),
            type: "danger",
            content: <span>
                Are you sure you want to delete this custom code?
            </span>,
            title: translate("text.confirm_delete_custom_code", [item.CustomCode]),
            buttonLabel: translate("btn.delete")
        })
    }

    const handleRestoreResourceClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmRestoreResourceClick(item),
            type: "danger",
            content: <span>
                Are you sure you want to restore this custom code?
            </span>,
            title: translate("text.confirm_restore_custom_code", [item.CustomCode]),
            buttonLabel: translate("btn.restore")
        })
    }

    const handleEditResourceClick = (item) => {
        setIsResourceDialogOpen(true);
        setIsDefaultDialogStateDirty(false);
        setSelectedItem(item);
    }

    const closeConfirmDialog = () => {
        setConfirmDialogData(Object.assign({}, confirmDialogData, {isOpen: false}))
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData(dispatch, query);
    }, [])

    /** Component Body
     ================================================================= */
    return (
        <React.Fragment>
                    <PageHeader
                        buttons={[
                            {
                                label: translate("btn.add_repair_code"),
                                type: "primary",
                                onClick: handleCreateResourceClick
                            }
                        ]}
                    />

                    <div className="pt-8 h-full space-y-8 w-full max-w-3xl mx-auto overflow-hidden">
                        <TableCard
                            addClass="border border-tm-gray-300 shadow"
                            bodyClass="rounded-md"
                        >
                            <ResourceTableTopBar
                                addClass="rounded-t-md ring-1 ring-black ring-opacity-5 px-4"
                            >
                                <FieldSearch
                                    name="query"
                                    value={queryFields?.query?.value}
                                    addClass="form-control"
                                    queryFields={queryFields}
                                    onChange={handleQueryChange}
                                    translate={translate}
                                />

                                {/*<ResourceTableButton />*/}
                                <div className="w-full justify-end flex">

                                    <button className="btn btn-header border-gray-300 border rounded-md p-1 mx-2"
                                            onClick={() => {
                                                fetchData(dispatch, query)
                                            }}>
                                        <ArrowPathIcon className={
                                            classNames(
                                                "h-6 w-6 text-tm-gray-800",
                                                isLoading ? "animate-spin" : undefined,
                                            )
                                        }/>
                                    </button>

                                    <Tooltip
                                        content={queryFields.archived.value ? translate("text.hide_archived") : translate("text.show_archived")}
                                    >
                                        <button
                                            className="btn btn-icon"
                                            onClick={() => handleQueryChange("archived", !!queryFields.archived.value ? 0 : 1)}
                                        >
                                            <TrashIcon className="w-5 h-5"/>
                                        </button>
                                    </Tooltip>
                                </div>
                            </ResourceTableTopBar>

                            {(isLoading || !!count) && (
                                <ResourceTable
                                    addTableClass="z-0 border-x border-tm-gray-300"
                                    data={data}
                                    fields={getFields()}
                                    queryFields={queryFields}

                                    options={{
                                        style: {
                                            stripedRows: true,
                                            horizontalLines: true,
                                            verticalLines: true,
                                            floatingActions: true
                                        }
                                    }}

                                    translate={translate}
                                    isLoading={isLoading}

                                    onDelete={handleDeleteResourceClick}
                                    onRestore={handleRestoreResourceClick}
                                    onEdit={handleEditResourceClick}
                                />
                            )}

                            {!isLoading && !count && (
                                <NoRecords
                                    addClass="p-10"
                                    queryFields={queryFields}
                                    title={!queryFields?.query?.value ? translate("text.no_repair_codes") : translate("text.no_repair_codes_query", [queryFields.query.value])}
                                    text={!queryFields?.query?.value ? translate("text.no_repair_codes_add") : translate("text.add_repair_code_query", [queryFields.query.value])}
                                    btnLabel={translate("btn.add_repair_code")}
                                    onBtnClick={(e) => handleCreateResourceClick(e, queryFields?.query?.value)}
                                />
                            )}

                            <TableCardFooter
                                show={!(!data.length && !resource.isLoading)}
                            >
                                <Pagination
                                    count={count}
                                    isLoading={isLoading}
                                    handleQueryChange={handleQueryChange}
                                    queryFields={queryFields}
                                    translate={translate}
                                />
                            </TableCardFooter>
                        </TableCard>
                    </div>

                    <ModalSaveResourceAuto
                        show={isResourceDialogOpen}
                        fields={getFields(selectedItem)}
                        initialFocusID="WorkOrder"
                        title={translate("modal_heading.add_repair_code")}
                        onClose={() => {
                            setIsResourceDialogOpen(false);
                        }}
                        isDirty={isDefaultDialogStateDirty}
                        onSubmit={(params) => {
                            if (!!params) {
                                if (!params.CustomCodeID) {
                                    delete params.CustomCodeID;

                                    dispatch(createResource({
                                        user: LocalStorage.get('user'),
                                        params: params,
                                        query: query,
                                        resource: getResourcePath(),
                                        piggyResource: getResourcePath(),
                                    }));
                                } else {
                                    dispatch(updateResource({
                                        user: LocalStorage.get('user'),
                                        params: params,
                                        query: query,
                                        resource: getResourcePath(),
                                        piggyResource: getResourcePath(),
                                    }));
                                }


                                setSelectedItem(params); // in case of server error save form data
                            }
                        }}
                        addFieldContainerClass={"col-span-full"}
                        isLoading={isLoading}
                        resource={resource}
                        translate={translate}
                    />

                    <ModalConfirm
                        show={confirmDialogData?.isOpen ?? false}
                        type={confirmDialogData?.type}
                        content={confirmDialogData?.content}
                        title={confirmDialogData?.title}
                        onConfirm={confirmDialogData?.onClick}
                        closeButtonLabel={confirmDialogData?.closeButtonLabel ?? translate("btn.cancel")}
                        buttonLabel={confirmDialogData?.buttonLabel}
                        translate={translate}
                        onClose={closeConfirmDialog}
                    />
        </React.Fragment>
    )
}

/** Page methods
 ================================================================= */
const getPrimaryField = () => "CustomCodeID";
const getResourcePath = () => Resources.WorkOrdersCustomCodes;

const getListPath = () => getResourcePath() + "_list"

const fetchData = (dispatch, query) => {
    dispatch(getResource({
        user: getUser(),
        resource: getResourcePath(),
        query: query
    }))
}

const getQueryFields = (translate) => {
    return (
        Object.assign(
            getDefaultQueryFields(getPrimaryField(), translate),
            {
                archived: new Field('archived', '', [''], false, 'checkbox', {
                    labelType: "float",
                    label: "archived",
                    hasActiveBadge: true,
                }),
            }
        )
    )
}

function getFields(item = {}) {
    const fieldTemplates = {
        CustomCodeID: new Field('CustomCodeID', '', [''], false, 'hidden', {omitSort: true, hideTable: true}),
        CustomCode: new Field('CustomCode', '', [''], false, 'text', {omitSort: true, addContainerClass: "col-span-full"}),
        CodeNumber: new Field('CodeNumber', '', [''], false, 'text', {omitSort: true, addContainerClass: "col-span-full"}),
    }

    return fillFieldsFromData(fieldTemplates, item)
}
