import React, {useEffect, useState} from "react";
import Resources from "../../../data/services/resources";
import {Field, FieldsManager} from "../../../data/services/fields";
import {getUser} from "../../../util/util";
import {useDispatch, useSelector} from "react-redux";
import {deleteResource, getResource, updateResource} from "../../../data/actions/resource";
import useQuery from "../../../hooks/use-query";
import {getLookup, getProp} from "../../../common/util/util-helpers";
import LocalStorage from "../../../util/localStorage";
import ResourceList from "../../../common/components/layout/layout-components/resource-list";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import PartsInfoDialog from "../../../common/components/modal/parts-info-dialog";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";
import Buttons from "../../../common/components/buttons";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY, DEFAULT_QUERY} from "../../../util/util-constants";

export default function PartsTab({history, match, translate}) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const thirdResource = useSelector((state) => state.thirdResource);
    const isLoading = getProp(resource, "isLoading", true);

    const getSearchFields = () => {
        const {
            ProductServiceCategoryID,
            VendorID,
            ManufacturerID,
            ProductServicePartLocationID
        } = FieldsManager.getFieldKeyValues(queryFields)

        return {
            searchFields: JSON.stringify({
                ProductServiceCategoryID: ProductServiceCategoryID,
                VendorID: VendorID,
                ManufacturerID: ManufacturerID,
                ProductServicePartLocationID: ProductServicePartLocationID
            })
        }
    }
    /** State
     ================================================================= */
    const [queryFields, setQueryFields] = useQuery(getQueryFields(translate), getListPath());
    const query = Object.assign({}, FieldsManager.getFieldKeyValues(queryFields), getSearchFields());
    const [isResourceDialogOpen, setIsResourceDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [confirmDialogData, setConfirmDialogData] = useState({});
    const [resourceErrorMessage, setResourceErrorMessage] = useState(false);

    /** UI events
     ================================================================= */
    useEffect(() => {
        fetchData(dispatch, query)
    }, [queryFields]);

    const handleEditResourceClick = (item) => {

        setIsResourceDialogOpen(true);
        setResourceErrorMessage(false);
        setSelectedItem(item);

    }

    const handleCreateResourceClick = () => {
        setIsResourceDialogOpen(true);
        setSelectedItem({});
    }

    const handleRestoreFromArchiveClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmResourceFromArchiveClick(item),
            type: "info",
            content: <span>
                Are you sure you want to restore Part <span className="font-bold text-red-600">{item.ProductService}</span>?
            </span>,
            title: translate("text.confirm_restore"),
            buttonLabel: translate("btn.restore")
        })
    }

    const handleConfirmResourceFromArchiveClick = (item) => {
        dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                ProductServiceID: item.ProductServiceID,
                ArchivedDate: 1
            },
            errorMessage: true, successMessage: `Part ${item.ProductService} restored.`,
            query: query,
            resource: getResourcePath(),
            piggyResource: getResourcePath()
        }));

        closeConfirmDialog();
    }

    const handleDeletePartClick = (item) => {
        setConfirmDialogData({
            isOpen: true,
            onClick: () => handleConfirmDeletePartClick(item),
            type: "danger",
            content: translate('message.confirm_archive_generic') + ` ${item.ProductService}?`,
            title: translate("text.confirm_delete"),
            buttonLabel: translate("btn.delete")
        })
    }

    const handleConfirmDeletePartClick = (item) => {
        dispatch(deleteResource({
            user: LocalStorage.get('user'),
            query: Object.assign({}, query, {ProductServiceID: item.ProductServiceID}),
            resource: getResourcePath(),
            piggyResource: getResourcePath(),
            successMessage: translate(`message.archived_generic`, [item.ProductService]),
            errorMessage: true
        }));

        closeConfirmDialog();
    }
    const closeConfirmDialog = () => {
        setConfirmDialogData(Object.assign({}, confirmDialogData, {isOpen: false}))
    }

    function getFields(item = {}) {
        const fieldTemplates = {
            ProductService: new Field('ProductService', '', ['empty'], false, 'text'),
            SKU: new Field('SKU', '', [''], false, 'text'),
            OEMNumber: new Field('OEMNumber', '', [''], false, 'text'),
            CrossRefNumber: new Field('CrossRefNumber', '', [''], false, 'text', {
                omitSort: true
            }),
            ProductServiceCategoryID: new Field('ProductServiceCategoryID', '', ['empty'], false, 'select-search'),
            ProductServicePartLocation: new Field('ProductServicePartLocation', '', [''], false, 'text', {
                omitSort: true
            }),
            UomID: new Field('UomID', '', [''], false, 'select', {}, {
                values: getLookup('Uom')
            }),
            ManufacturerID: new Field('ManufacturerID', '', [], false, 'select-search'),
        }

        let filledFields = fillFieldsFromData(fieldTemplates, item)


        if (!!item.CrossRefNumber && typeof item.CrossRefNumber === 'string') {
            filledFields.CrossRefNumber.value = item.CrossRefNumber.split(",").reduce((memo, it) => {
                memo.push({label: it, value: it})
                return memo;
            }, []);
        }

        return filledFields
    }


    /** Component Body
     ================================================================= */
    return (
        <React.Fragment>
            <ResourceList
                excludeActiveFilters={['ProductServiceTypeID', 'limit', 'offset', 'sort', 'sortBy']}
                fields={getFields({}, translate)}

                resource={resource}
                // fetchData={fetchData}
                isLoading={isLoading}
                queryFields={queryFields}
                setQueryFields={setQueryFields}
                listPath={getListPath()}
                onRefreshTable={() => fetchData(dispatch, query)}
                dispatch={dispatch}
                query={query}

                translate={translate}
                // onMount={fetchData}
                selects={getSelects()}

                onRowClick={handleEditResourceClick}
                onEdit={handleEditResourceClick}
                onDelete={handleDeletePartClick}
                onRestore={handleRestoreFromArchiveClick}

                afterFiltersHtml={
                    <Buttons
                        buttons={[
                            {
                                label: translate("btn.create"),
                                type: "primary",
                                onClick: handleCreateResourceClick
                            }
                        ]}
                    />
                }
            />

            <ModalConfirm
                show={confirmDialogData?.isOpen ?? false}
                type={confirmDialogData?.type}

                title={confirmDialogData?.title}
                onConfirm={confirmDialogData?.onClick}
                closeButtonLabel={confirmDialogData?.closeButtonLabel ?? translate("btn.cancel")}
                buttonLabel={confirmDialogData?.buttonLabel}
                translate={translate}
                onClose={closeConfirmDialog}
            >
                {confirmDialogData?.content}
            </ModalConfirm>

            {isResourceDialogOpen && (
                <PartsInfoDialog
                    query={query}
                    editedItem={selectedItem}
                    onClose={() => setIsResourceDialogOpen(false)}
                    translate={translate}
                    thirdResource={thirdResource}
                    dispatch={dispatch}
                />
            )}
        </React.Fragment>
    )
}

/** Page methods
 ================================================================= */
const getResourcePath = () => Resources.WorkOrderProductsServices;

const getListPath = () => getResourcePath() + "_list"

const fetchData = (dispatch, query) => {
    dispatch(getResource({
        user: getUser(),
        resource: getResourcePath(),
        query: query,

    }))

}

const getQueryFields = () => {
    return {
        query: new Field('query', '', [''], false, 'search', {}, {}),
        offset: new Field('offset', '0', [''], false, 'hidden', {}, {}),
        sortBy: new Field('sortBy', 'ProductService', [''], false, 'hidden', {}, {}),
        sort: new Field('sort', 'ASC', [''], false, 'hidden', {}, {}),
        ProductServiceCategoryID: new Field('ProductServiceCategoryID', '', [''], false, 'select-search', {}, {
            isClearable: true
        }),
        VendorID: new Field('VendorID', '', [''], false, 'select-search', {
            label: 'recommendedVendor',
            addContainerClass: 'col-span-full'
        }, {
            isClearable: true
        }),
        ManufacturerID: new Field('ManufacturerID', '', [], false, 'select-search', {}, {
            isClearable: true
        }),
        ProductServicePartLocationID: new Field('ProductServicePartLocationID', '', [''], false, 'select-search', {}, {
            isClearable: true
        }),
        archived: new Field('archived', '', [''], false, 'checkbox'),
        limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
    }
}


function getSelects() {
    return {
        ManufacturerID: {
            api: 'api/' + Resources.WorkOrderProductsManufacturersQuick,
            query: DEFAULT_QUERY,
            searchMap: (item) => ({
                value: item.ManufacturerID,
                label: item.Manufacturer
            })
        },
        ProductServicePartLocationID: {
            api: 'api/' + Resources.WorkOrderPartLocationsQuick,
            query: DEFAULT_QUERY,
            searchMap: (item) => ({
                label: item.ProductServiceLocation,
                value: item.ProductServiceLocationID
            })
        },
        VendorID: {
            api: 'api/' + Resources.Vendors,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.LegalName + ", " + item.AddressName,
                value: item.VendorID
            })
        }
    }
}
