import {processTabs} from "../../../common/util/util-helpers";
import Resources from "../../../data/services/resources";
import React, {useState} from "react";
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import NavResponsive from "../../../common/components/nav-responsive";
import {Field} from "../../../data/services/fields";
import moment from "moment/moment";
import WorkOrdersReportsTab from "./work-orders-reports-tab";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY} from "../../../common/util/util-consts";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import {checkPerm} from "../../../util/util";
import {READ_PERM} from "../../../util/util-constants";
import {useDispatch} from "react-redux";
import {showGlobalModal} from "../../../data/actions/ui";

export default function WorkOrdersReports({translate, match, history, location}) {
    const dispatch = useDispatch();
    function getTabs() {
        return processTabs([
            {name: 'MaintenanceCostTruck', resource: Resources.WorkOrdersReportsMaintenanceCostTruck},
            {name: 'MaintenanceCostTrailer', resource: Resources.WorkOrdersReportsMaintenanceCostTrailer},
        ]);
    }

    function handleTabChange(resource) {
        setTabs(
            tabs.map((it) => {
                it.current = it.resource === resource;
                return it
            })
        )
    }

    const [tabs, setTabs] = useState(getTabs());
    const currentTab = tabs.find(tab => tab.current);

    const handleViewTruckClick = (truckID) => {
        dispatch(showGlobalModal('ViewTruckCard', truckID));
    }

    const handleViewTrailerClick = (trailerID) => {
        dispatch(showGlobalModal('ViewTrailerCard', trailerID));
    }

    return (
        <Layout
            match={match}
            history={history}
            location={location}
            translate={translate}
        >
            <Page>
                <PageHeader
                    title={translate("page.work-order.reports")}
                    titleClass="mb-5 text-2xl ml-4"
                />
                <div className="mb-5">
                    <NavResponsive
                        tabs={tabs}
                        onTabChange={handleTabChange}
                        translate={translate}
                    />
                </div>

                {currentTab.resource === Resources.WorkOrdersReportsMaintenanceCostTruck && (
                    <WorkOrdersReportsTab
                        tableKey={'TruckID'}
                        getFields={() => {
                            return {
                                'TruckID': new Field('TruckID', '', [''], false, 'select-search', {
                                    render: (item) => {
                                        if (!item) return null;

                                        if (item.TruckID) {
                                            return (
                                                <CellButton
                                                    onClick={() => checkPerm(Resources.TrucksInfo, READ_PERM) && handleViewTruckClick(item.TruckID)}
                                                >
                                                    {item.Truck}
                                                </CellButton>
                                            )
                                        } else {
                                            return (<span>{item.Truck}</span>)
                                        }
                                    },
                                    addContainerClass: "col-span-full sm:col-span-6 lg:col-span-3"
                                }),
                                'PartsAmount': new Field('PartsAmount', '', [''], false, 'money', {
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3'
                                }),
                                'LaborAmount': new Field('LaborAmount', '', [''], false, 'money', {
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3'
                                }),
                                'OtherAmount': new Field('OtherAmount', '', [''], false, 'money', {
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3'
                                }),
                                'TotalAmount': new Field('TotalAmount', '', [''], false, 'money', {
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3'
                                }),
                            }
                        }}
                        additionalQuery={{
                            StartDate: new Field('StartDate', moment().subtract('month', 1).format('YYYY-MM-DD HH:mm:ss'), [], false, 'date', {}, {}),
                            EndDate: new Field('EndDate', moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'), [], false, 'date', {}, {}),
                            TruckIDs: new Field('TruckIDs', '', [''], false, 'select-search', {}, {
                                multi: true,
                                all: true
                            }),
                            ChargeOrganizationIDs: new Field('ChargeOrganizationIDs', '', [''], false, 'select-search', {}, {
                                multi: true,
                                all: true
                            }),
                            ChargeContactIDs: new Field('ChargeContactIDs', '', [''], false, 'select-search', {}, {
                                multi: true,
                                all: true
                            }),
                            ScheduledMaintenanceIDs: new Field('ScheduledMaintenanceIDs', '', [''], false, 'select-search', {}, {
                                multi: true,
                                all: true
                            })
                        }}
                        resourcePath={currentTab.resource}
                        storagePath={currentTab.name}
                        selects={{
                            TruckIDs: {
                                api: 'api/' + Resources.TrucksQuick,
                                query: Object.assign(
                                    DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    {NotSoldRetired: 1}
                                ),
                                searchMap: (item) => ({
                                    value: item.TruckID,
                                    label: item.TruckNumber
                                })
                            },
                            ChargeOrganizationIDs: {
                                api: 'api/' + Resources.OrganizationsQuick,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    value: item.OrganizationID,
                                    label: item.LegalName
                                })
                            },
                            ChargeContactIDs: {
                                api: 'api/' + Resources.ContactsQuick,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    value: item.ContactID,
                                    label: item.FirstName + " " + item.LastName
                                })
                            },
                            ScheduledMaintenanceIDs: {
                                api: 'api/' + Resources.WorkOrderScheduledMaintenance,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    value: item.ScheduledMaintenanceID,
                                    label: item.ScheduledMaintenanceType + ", " + item.ScheduleNotes
                                })
                            }
                        }}
                        translate={translate}
                    />
                )}

                {currentTab.resource ===  Resources.WorkOrdersReportsMaintenanceCostTrailer && (
                    <WorkOrdersReportsTab
                        tableKey={'TrailerID'}
                        getFields={() => {
                            return {
                                TrailerID: new Field('TrailerID', '', [''] , false, 'select-search', {
                                    render: (item) => {
                                        if (!item) return null;

                                        if (item.TrailerID) {
                                            return (
                                                <CellButton
                                                    onClick={() => checkPerm(Resources.Trailer, READ_PERM) && handleViewTrailerClick(item.TrailerID)}
                                                >
                                                    {item.Trailer}
                                                </CellButton>
                                            )
                                        } else {
                                            return item.Trailer;
                                        }
                                    },
                                    addContainerClass: "col-span-full sm:col-span-6 lg:col-span-3"
                                }),
                                'PartsAmount': new Field('PartsAmount', '', [''], false, 'money', {
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3'
                                }),
                                'LaborAmount': new Field('LaborAmount', '', [''], false, 'money', {
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3'
                                }),
                                'OtherAmount': new Field('OtherAmount', '', [''], false, 'money', {
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3'
                                }),
                                'TotalAmount': new Field('TotalAmount', '', [''], false, 'money', {
                                    addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3'
                                }),
                            }
                        }}
                        additionalQuery={{
                            StartDate: new Field('StartDate', moment().subtract('month', 1).format('YYYY-MM-DD HH:mm:ss'), [], false, 'date', {}, {}),
                            EndDate: new Field('EndDate', moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'), [], false, 'date', {}, {}),
                            TrailerIDs: new Field('TrailerIDs', '', [''], false, 'select-search', {}, {
                                multi: true,
                                all: true
                            }),
                            ChargeOrganizationIDs: new Field('ChargeOrganizationIDs', '', [''], false, 'select-search', {}, {
                                multi: true,
                                all: true
                            }),
                            ChargeContactIDs: new Field('ChargeContactIDs', '', [''], false, 'select-search', {}, {
                                multi: true,
                                all: true
                            }),
                            ScheduledMaintenanceIDs: new Field('ScheduledMaintenanceIDs', '', [''], false, 'select-search', {}, {
                                multi: true,
                                all: true
                            })
                        }}
                        resourcePath={currentTab.resource}
                        storagePath={currentTab.name}
                        selects={{
                            TrailerIDs: {
                                api: 'api/' + Resources.TrailersQuick,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    value: item.TrailerID,
                                    label: item.TrailerNumber
                                })
                            },
                            ChargeOrganizationIDs: {
                                api: 'api/' + Resources.OrganizationsQuick,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    value: item.OrganizationID,
                                    label: item.LegalName
                                })
                            },
                            ChargeContactIDs: {
                                api: 'api/' + Resources.ContactsQuick,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    value: item.ContactID,
                                    label: item.FirstName + " " + item.LastName
                                })
                            },
                            ScheduledMaintenanceIDs: {
                                api: 'api/' + Resources.WorkOrderScheduledMaintenance,
                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                searchMap: (item) => ({
                                    value: item.ScheduledMaintenanceID,
                                    label: item.ScheduledMaintenanceType + ", " + item.ScheduleNotes
                                })
                            }
                        }}
                        translate={translate}
                    />
                )}
            </Page>
        </Layout>
    )
}
