import {render} from "react-dom";
import {createElement} from "react";
import App from "./app";
import "./common/styles/variables.css";
import "./common/styles/style.css";
import "./styles/react-leaflet-markercluster.css";
import "./styles/react-leaflet.css";
import "./common/styles/tooltip-style.css";
import * as serviceWorker from "./serviceWorker";
import enLocaleData from "./assets/locale/en.json";
import woLocaleData from "./assets/locale/en-wo.json";
import * as Sentry from '@sentry/react'
import {_ENV_MODE} from "./util/env";

// import generalJSON from "./assets/locale/general.json";
// import authJSON from "./assets/locale/auth.json";
// import contactsJSON from "./assets/locale/contacts.json";
// import companiesJSON from "./assets/locale/companies.json";
// import myCompanyJSON from "./assets/locale/my-company.json";
// import placesJSON from "./assets/locale/places.json";

/*
 * Globals
 =========================================================================================*/
window._DEV_MODE = true;

if (_ENV_MODE === 'prod' || _ENV_MODE === 'production') {
    Sentry.init({
        dsn: "https://c0c5177cbef23f814258684f299ac1a5@o4508998666485760.ingest.us.sentry.io/4509004481429504",
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ["https:fast-api.accur8.services/"],
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0,
        // Session Replay
        replaysSessionSampleRate: 0.2,
        replaysOnErrorSampleRate: 1.0,
        release: "truck-wo-frontend@" + process.env.REACT_APP_VERSION + "+" + process.env.REACT_APP_COMMIT_HASH,
        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications'
        ]
    });
}

/*
 * Locale data preload
 =====================================================================================*/

//const localeData = {...generalJSON, ...authJSON, ...contactsJSON, ...companiesJSON, ...myCompanyJSON, ...placesJSON};
const localeData = {...enLocaleData, ...woLocaleData};

render(
    createElement(App, {locale: 'en', localeData: localeData}),
    window.document.getElementById('root')
);

/*
 * Service worker
 ===================================================================================*/
serviceWorker.unregister();
