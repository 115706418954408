import {Field, FieldsManager} from "../../../../data/services/fields";
import ResourceTable from "../../../../common/components/resource-table";
import useQueryNew from "../../../../hooks/use-query-new";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getResource} from "../../../../data/actions/resource";
import LocalStorage from "../../../../util/localStorage";
import {
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    saveTableColumns
} from "../../../../common/util/util-helpers";
import TableCard from "../../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../../common/components/resource-table/table-components/table-filters";
import {updateQuery} from "../../../../util/util";
import NoRecordsTable from "../../../../common/components/no-records-found/no-records-table";
import {download} from "../../../../data/actions/download";
import {currentDate} from "../../../../common/util/util-dates";
import Tooltip from "../../../../common/components/tooltip";
import DocumentArrowDownIcon from "@heroicons/react/20/solid/DocumentArrowDownIcon";
import Tippy from "@tippyjs/react";
import TableSettingsPopOver from "../../../../common/components/resource-table/table-components/table-settings-popover";
import TableOptionsDialog from "../../../../common/components/resource-table/table-components/table-options-dialog";
import {cloneDeep} from "../../../../common/util/util-vanilla";
import PayDateFilter from "../../../accounting/carrier-pay/carrier-pay-date-filter";
import {excludeFields} from "../../../../common/util/util-fields";

export default function WorkOrdersReportsTab({additionalQuery, resourcePath, storagePath, selects = {}, getFields, tableKey, translate}) {
    const dispatch = useDispatch();
    const [queryFields, setQueryFields] = useQueryNew(getQueryFields(), storagePath);
    const [tableOptions, setTableOptions] = useState(getTableOptions(storagePath, translate))

    const [isColumnsDialogVisible, setIsColumnsDialogVisible] = useState(false)

    const resource = useSelector(state => state.resource);
    const data = resource?.data?.list ?? [];
    const isLoading = resource.isLoading ?? false;

    const fetchData = useCallback(() => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: resourcePath,
            query: FieldsManager.getFieldKeyValues(queryFields)
        }))
    }, [resourcePath, queryFields, dispatch]);

    const downloadExcel = () => {
        dispatch(download({
            user: LocalStorage.get('user'),
            resource: resourcePath,
            query: Object.assign({format: 'EXCEL', name: 'report_' + currentDate() + '.xlsx'},FieldsManager.getFieldKeyValues(queryFields))
        }))
    }

    function handleQueryChange(name, value) {
        let queryUpdate = updateQuery(queryFields, name, value);
        if (name === 'query') {
            queryUpdate.offset.value = "0"
        }

        if (name === 'limit') {
            queryUpdate.offset.value = "0"
        }

        setQueryFields(queryUpdate);
    }

    function getQueryFields() {
        return Object.assign({
                query: new Field('query', '', [''], false, 'search', {hideLabel: true, labelType: "float"})
            },
            additionalQuery,
            {
                offset: new Field('offset', '0', [''], false, 'text'),
                sort: new Field('sort', 'ASC', [''], false, 'text'),
                sortBy: new Field('sortBy', tableKey, [''], false, 'text'),
                limit: new Field('limit', "10", [''], false, 'select', {
                    hideLabel: true,
                    labelType: "float"
                }, {menuPlacement: "top"})
            });
    }


    function getTableOptions(pagePath, translate) {
        const tablePageDefaults = {
            behaviour: {
                canAdjustWidth: false,
                hasMenu: false
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        return getDefaultTableOptions(getFields(), tablePageDefaults, storagePath, translate);
    }

    const getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(getFields(),{
            behaviour: {
                canAdjustWidth: false,
                hasMenu: false
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        } , translate)
    }

    const setOptions = (options) => {
        setTableOptions(options)
        saveTableColumns(storagePath, options)
    }

    function setTableColumnOptions(columns) {
        let tableOptionsUpdate = cloneDeep(tableOptions)

        tableOptionsUpdate.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        setTableOptions(tableOptionsUpdate)
        setIsColumnsDialogVisible(false)
        saveTableColumns(storagePath, tableOptionsUpdate)
    }

    const handleQueryInputChange = (name, value) => {
        setQueryFields(FieldsManager.updateField(queryFields, name, value));
    }

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <TableCard>
            <div className="flex ml-auto">
                <div className={'flex mr-4'}>
                    <Tippy content={translate('text.download_excel')}>
                        <button
                            className="btn-icon"
                            onClick={downloadExcel}
                        >
                            <DocumentArrowDownIcon className="w-5 h-5"/>
                        </button>
                    </Tippy>

                    <div className={"ml-auto"}>
                        <TableSettingsPopOver
                            options={tableOptions}
                            setOptions={setOptions}
                            toggleColumnSettingsDialog={() => setIsColumnsDialogVisible(true)}
                            translate={translate}
                        />
                    </div>
                </div>
            </div>

            <TableFilters
                forceDialog
                hideLimit
                filterFields={queryFields}
                handleInputChange={handleQueryChange}
                translate={translate}
                onRefreshTable={fetchData}
                selects={selects}
                isLoading={isLoading}
                customHtml={
                    <PayDateFilter
                        queryFields={queryFields}
                        translate={translate}
                        updateQueryFields={(queryFieldsUpdate) => {
                            setQueryFields(queryFieldsUpdate);
                        }}
                        hasDateType={false}
                        onQueryChange={handleQueryInputChange}
                    />
            }
            />

                    <ResourceTable
                        tableKey={tableKey}
                        data={data}
                        fields={getFields()}
                        options={tableOptions}
                        isLoading={isLoading}
                        translate={translate}
                        queryFields={queryFields}
                        onSortChange={(fieldName) => handleQueryChange("sortBy", fieldName)}
                        saveTableOptions={setTableOptions}
                        maxHeightClass={"max-h-[calc(100vh-23rem)]"}
                    />

                    <NoRecordsTable
                        show={(data.length === 0) && !isLoading}
                        title={'No matching records found'}
                        className={"pb-12 pt-16 px-6"}
                    />

            <TableOptionsDialog
                show={isColumnsDialogVisible}
                pagePath={storagePath}
                columns={tableOptions.columns}
                setTableColumnOptions={setTableColumnOptions}
                getDefaultTableColumnOptions={getDefaultTableColumnOptions}
                onClose={() => setIsColumnsDialogVisible(false)}
                translate={translate}
            />
        </TableCard>
);
}