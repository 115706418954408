import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createResource, updateResource } from '../../../../data/actions/resource'
import LocalStorage from '../../../../util/localStorage'
import { fieldsToHtml, fillFieldsFromData } from '../../../../common/util/util-fields';
import { getLookup } from '../../../../common/util/util-helpers'
import { Field, FieldsManager } from '../../../../data/services/fields'
import Resources from '../../../../data/services/resources'
import { CurrencyDollarIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import {getCountryCodes} from "../../../../common/util/countryCodes";
import Env from '../../../../util/env'
import {
    COUNTRY_ID_USA,
    DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE,
    SCAC_CODE_FIELD_LENGTH
} from '../../../../util/util-constants'
import Dropzone from 'react-dropzone'
import ReactCrop from 'react-image-crop'
import moment from 'moment-timezone'
import DialogTabs from "../../../../common/components/tabs-navigation/dialog-tabs";
import ModalHeader from "../../../../common/components/modal/modal-header";
import ModalFooter from "../../../../common/components/modal/modal-footer";
import {getJWT} from "../../../../common/util/util-auth";
class CreateDivisionDialog extends Component {

    constructor (props) {
        super(props)

        const tabs = [
            {
                id: 1,
                name: 'Info',
                resource: 'Info',
                icon: InformationCircleIcon,
                visible: true,
                invalid: false,
                current: true
            },
            {
                id: 2,
                name: 'Accounting',
                resource: 'Accounting',
                icon: CurrencyDollarIcon,
                visible: true,
                invalid: false,
            },
        ]
        this.state = {
            InfoFields: this.getFields(),
            AccountingFields: this.getAccountingFields(),
            selectedTab: 'Info',
            tabs: tabs,

            files: [],

            src: null,
            crop: {
                unit: '%',
                width: 100,
                height: 50
            },

            canSubmit: false
        }
        this.fileImageObject = null

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show !== this.props.show && !!this.props.show) {
            this.fileImageObject = null
            this.setState({
                fields: this.getFields(),
                files: [],
                src: null,
                croppedImageUrl: null,
                canSubmit: false
            })
        }
    }

    /** UI Events
     ================================================================= */

    handleInputChange = (name, value, state) => {
        const fields = FieldsManager.updateField(this.state[state], name, value)
        if (name === "CountryID") {
            if (Number(value) === COUNTRY_ID_USA) {
                fields.StateID.type = 'select'
            } else {
                fields.StateID.type = 'hidden'
                fields.StateID.value = ''
            }
        }

        this.setState({ canSubmit: true, [state]: fields })
    }

    onDropAccepted = (acceptedFiles) => {
        this.setState({
            files: acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })),
            canSubmit: true
        })
    }

    onSelectFile = acceptedFiles => {
        if (acceptedFiles) {
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                this.setState({
                    src: reader.result,
                    canSubmit: true
                })
            )
            reader.readAsDataURL(acceptedFiles[0])
        }
    }

    // If you setState the crop in here you should return false.

    onImageLoaded = image => {
        this.imageRef = image
    }

    onCropComplete = crop => {
        this.makeClientCrop(crop)
    }

    onCropChange = (crop) => {
        this.setState({crop})
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            )
            this.setState({croppedImageUrl})
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas')
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        canvas.width = crop.width
        canvas.height = crop.height
        const ctx = canvas.getContext('2d')

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    return
                }
                blob.name = fileName
                window.URL.revokeObjectURL(this.fileUrl)
                this.fileUrl = window.URL.createObjectURL(blob)
                this.fileImageObject = new File([blob], 'company-logo-' + moment().valueOf() + '.png')
                resolve(this.fileUrl)
            }, 'image/png')
        })
    }

    /** Helpers
     ================================================================= */

    submit = (event) => {
        event && event.preventDefault()

        this.setState({
            InfoFields: FieldsManager.validateFields(this.state.InfoFields),
            AccountingFields: FieldsManager.validateFields(this.state.AccountingFields),
        }, () => {

            if (!FieldsManager.checkFieldsForErrors(this.state.InfoFields)) {
                this.handleTabChange('Info')
            } else if (!FieldsManager.checkFieldsForErrors(this.state.AccountingFields)) {
                this.handleTabChange('Accounting')
            } else {
                if (this.props.selectedItem) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({},
                            FieldsManager.getFieldKeyValues(this.state.InfoFields),
                            FieldsManager.getFieldKeyValues(this.state.AccountingFields),
                            {
                                id: this.props.selectedItem.DivisionID,
                                DivisionID: this.props.selectedItem.DivisionID
                            }),
                        errorMessage: true, successMessage: `Division updated successfully!`,
                        query: this.props.query,
                        resource: Resources.Divisions,
                        piggyResource: Resources.Divisions,
                        id: this.props.selectedItem.DivisionID,
                        file: [this.fileImageObject],
                        fileResource: Resources.DivisionsImage
                    }))
                } else {
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({},
                            FieldsManager.getFieldKeyValues(this.state.InfoFields),
                            FieldsManager.getFieldKeyValues(this.state.AccountingFields),
                        ),
                        errorMessage: true, successMessage: `Division created successfully!`,
                        query: this.props.query,
                        file: [this.fileImageObject],
                        fileResource: Resources.DivisionsImage,
                        resource: Resources.Divisions,
                        piggyResource: Resources.Divisions,
                    }))
                }

                if (!!this.props.setDialogState) {
                    this.props.setDialogState(
                        Object.assign({},
                            this.props.selectedItem,
                            FieldsManager.getFieldKeyValues(this.state.InfoFields),
                            FieldsManager.getFieldKeyValues(this.state.AccountingFields),
                        ))
                }
                this.hideModal()
            }
        })
    }

    getFields = () => {
        const fieldTemplates = {
            CountryID: new Field('CountryID', '1', ['empty'], false, 'select'),
            GooglePlaces: new Field('GooglePlaces', '', [], false, 'google-locations', {
                setLocations: this.setLocations,
                addContainerClass: 'col-span-2'
            }),
            AddressName: new Field('AddressName', '', [], false, 'text', { addContainerClass: 'col-span-full' }),
            AddressName2: new Field('AddressName2', '', [], false, 'text', { addContainerClass: 'col-span-full' }),
            CityName: new Field('CityName', '', ['empty']),
            StateID: new Field('StateID', '', [], false, 'select'),
            PostalCode: new Field('PostalCode', '', ['empty']),
            DivisionName: new Field('DivisionName', this.props.prefilled ?? '', ['empty'], false, 'text', { addContainerClass: 'col-span-full' }),
            AreaCode: new Field('AreaCode', '', [''], false, 'select'),
            PhoneNumber: new Field('PhoneNumber', '', [''], false, 'mask', {}, {
                showMask: true,
                mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]
            }),
            PhoneExtension: new Field('PhoneExtension', '', ['']),
            SCAC: new Field('SCAC', '', [], false, 'text', {}, {
                upperCaseOnly: true,
                max: SCAC_CODE_FIELD_LENGTH
            }),
            MC: new Field('MC', '', [], false, 'text'),
            Description: new Field('Description', '', [], false, 'textarea', {addContainerClass: 'col-span-full'})

        }

        console.log('this.props.selectedItem', this.props.selectedItem)

        return this.props.selectedItem ? fillFieldsFromData(fieldTemplates, this.props.selectedItem) : fieldTemplates
    }

    getAccountingFields = () => {
        const fieldTemplates = {
            AccountName: new Field('AccountName', '', [], false, 'text'),
            BankName: new Field('BankName', '', [], false, 'text'),
            AccountNumber: new Field('AccountNumber', '', [], false, 'text'),
            RoutingNumber: new Field('RoutingNumber', '', [], false, 'text')
        }

        return this.props.selectedItem ? fillFieldsFromData(fieldTemplates, this.props.selectedItem) : fieldTemplates
    }

    hideModal = () => {
        this.props.close()
    }

    setLocations = (fields) => {
        let updatedFields = this.state.InfoFields

        for (const [key, value] of Object.entries(fields)) {
            if (key in this.state.InfoFields) {
                updatedFields = FieldsManager.updateField(updatedFields, key, value)
            }
        }

        this.setState({
            InfoFields: updatedFields,
            canSubmit: true
        })
    }

    handleTabChange = (name) => {
        const tabs = this.state.tabs.map((it) => {
            it.current = it.name === name
            it.invalid = it.invalid || !it.name === name
            return it
        })

        this.setState({ tabs, selectedTab: name })
    }

    /** Render
     ================================================================= */

    render () {
        const { translate } = this.props

        const InfoMetadata = {
            StateID: getLookup('State'),
            CountryID: getLookup('Country'),
            AreaCode: getCountryCodes()
        }

        const files = this.state.files.map((item, i) => (
            <span>{item.name}</span>
        ))

        const InfoFieldsToHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.InfoFields)), translate, (name, value) => this.handleInputChange(name, value, 'InfoFields'), InfoMetadata)
        const AccountingFieldsToHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.AccountingFields)), translate, (name, value) => this.handleInputChange(name, value, 'AccountingFields'), {})

        return (
            <React.Fragment>
                <ModalHeader
                    title={translate(`text.${this.props.selectedItem ? 'edit' : 'create'}`) + ' ' + translate('text.division')}
                    onClose={this.hideModal}
                />

                <div className="grid grid-cols-5">
                    <div
                        className="pr-3 border-tm-gray-200 border-r hidden lg:block w-full sticky top-0 col-span-1">
                        <DialogTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>
                    <div className="col-span-4 p-5 h-dialog-body">
                        {this.state.selectedTab === 'Info' && (
                            <div className={'grid grid-cols-3 gap-5'}>
                                {InfoFieldsToHtml}
                                <div>
                                    <div className="col-span-12">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p className="text-tm-gray-900">{translate('field.DivisionLogo')}</p>
                                                <p className="text-tm-gray-500">For optimal display please use image that
                                                    is at least 85px wide and 85px high.</p>

                                                {!this.state.croppedImageUrl && (
                                                    <div
                                                        className="col-md-12 flex items-center justify-center equal-height-container">
                                                        <img
                                                            className={'img-thumbnail border-0  mb-4 list-thumbnail'}
                                                            src={
                                                                (this.state.files.length > 0) ? this.state.files[0].preview : this.props.selectedItem ? Env.getApiUrl('api/' + Resources.DivisionsImage, {
                                                                    token: getJWT().access_token,
                                                                    id: this.props.selectedItem.DivisionID,
                                                                    v: moment(this.props.selectedItem.CreateUpdateDate).valueOf()
                                                                }) : ''
                                                            }
                                                            key={
                                                                (this.state.files.length > 0) ? this.state.files[0].preview : this.props.selectedItem ? Env.getApiUrl('api/' + Resources.DivisionsImage, {
                                                                    token: getJWT().access_token,
                                                                    id: this.props.selectedItem.DivisionID,
                                                                    v: moment(this.props.selectedItem.CreateUpdateDate).valueOf()
                                                                }) : ''
                                                            }
                                                            alt={''}
                                                        />
                                                    </div>
                                                )}

                                                {this.state.croppedImageUrl && (
                                                    <React.Fragment>
                                                        <h6 className="text-tm-gray-500">Preview:</h6>
                                                        <div className="text-center">
                                                            <div style={{ width: '85px', height: '85px' }}
                                                                 className="mb-4 border border-color-table d-inline-block">
                                                                <img alt="Crop" style={{ width: '100%' }}
                                                                     src={this.state.croppedImageUrl}/>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )}

                                                {this.state.src && (
                                                    <React.Fragment>
                                                        <h6 className="text-tm-gray-500">Select area to crop image
                                                            to:</h6>

                                                        <div className="row mb-3">
                                                            <div className="col">
                                                                <ReactCrop
                                                                    src={this.state.src}
                                                                    crop={this.state.crop}
                                                                    ruleOfThirds
                                                                    onImageLoaded={this.onImageLoaded}
                                                                    onComplete={this.onCropComplete}
                                                                    onChange={this.onCropChange}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="text-right">
                                                            <button
                                                                onClick={() => this.setState({
                                                                    src: null,
                                                                    croppedImageUrl: null
                                                                })}
                                                                className="btn btn-xs btn-outline">
                                                                Cancel Image Cropping
                                                            </button>
                                                        </div>
                                                    </React.Fragment>
                                                )}

                                                {!this.state.src && (
                                                    <div className="col-md-12">
                                                        <Dropzone
                                                            onDrop={(acceptedFiles) => {
                                                                this.onSelectFile(acceptedFiles)
                                                            }}
                                                            onDragEnter={this.onDragEnter}
                                                            onDragLeave={this.onDragLeave}
                                                            onDropAccepted={this.onDrop}
                                                            maxFiles={1}
                                                            accept={DEFAULT_IMAGES_ACCEPTABLE_EXTENSIONS}
                                                            maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                                                            multiple={false}
                                                        >
                                                            {({ getRootProps, getInputProps }) => (
                                                                <section>
                                                                    <div {...getRootProps()}
                                                                         className={'dropzone bg-inverse text-tm-gray-900' + (this.state.dropzoneActive ? 'active' : '')}>
                                                                        <input {...getInputProps()} />
                                                                        <p>{translate('field.drag_n_drop')}</p>
                                                                        <em>{translate('field.image_types')}</em>
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                        {files}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )}
                        {this.state.selectedTab === 'Accounting' && (
                            <div className="grid gap-4">
                                {AccountingFieldsToHtml}
                            </div>
                        )}
                    </div>
                </div>

                <ModalFooter
                    buttonDisabled={!this.state.canSubmit}
                    buttonLabel={translate(`btn.save`)}
                    onButtonClick={this.state.canSubmit && this.submit}

                    closeButtonLabel={translate('btn.cancel')}
                    onClose={this.hideModal}
                />
            </React.Fragment>

        )
    }
}

export default connect(state => state)(CreateDivisionDialog)
