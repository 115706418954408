import React, {Component} from 'react'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../common/util/localStorage'
import {Field, FieldsManager} from '../../../data/services/fields'
import {CREATE_PERM, DEFAULT_QUERY_LIMIT, DELETE_PERM, UPDATE_PERM} from '../../../common/util/util-consts'
import {checkPerm, getProp} from '../../../common/util/util-helpers'
import Resources from "../../../data/services/resources";
import {excludeFields, fillFieldsFromData} from "../../../common/util/util-fields";
import Card from "../../../common/components/card";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ResourceTable from "../../../common/components/resource-table";
import ModalConfirm from "../../../common/components/modal/modal-confirm";

export default class EmailTemplatesTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: excludeFields(this.getFields(), ['Content']),
            queryFilterFields: this.getQueryFilterFields(),
            offset: 0,
            sort: 'ASC',
            sortBy: '',
            prefilled: '',
            paginationPage: 1,
            editModalOpen: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.props.tabResource,
            query: this.getQuery()
        }))
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData();
        })
    };

    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return this.props.tabResource
    }

    getPrimaryKey = () => {
        return 'EmailTemplateID'
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            EmailTemplateName: new Field('EmailTemplateName', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
            Subject: new Field('Subject', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
            Content: new Field("Content", "", ['empty'], false, 'rich-text', {
                addContainerClass: 'col-span-full',
                keywords: [
                    // Load Carrier/Driver
                    {text: 'LoadNumber', label: "Load number", desc: 'Load number', type: 1},
                    {text: 'Carrier', label: "Carrier", desc: 'Carrier', type: 1},
                    {text: 'DriverFirstName', label: "Driver first name", desc: 'Driver First Name', type: 1},
                    {text: 'DriverLastName', label: "Driver last name", desc: 'Driver Last Name', type: 1},

                    // Logged user/Company
                    {text: 'UserFirstName', label: "User first name", desc: 'Logged User First Name', type: 2},
                    {text: 'UserLastName', label: "User last name", desc: 'Logged User Last Name', type: 2},
                    {text: 'CompanyName', label: "Company name", desc: 'Our Company Name', type: 2},

                    // Load customer
                    {text: 'Customer', label: "Customer", desc: 'Customer', type: 3},
                    {
                        text: 'CustomerReferenceNumber',
                        label: "Customer ref. no.",
                        desc: 'Customer Reference number',
                        type: 3
                    },
                    {text: 'Pickup', label: "Pickup", desc: 'Load First Pickup', type: 3},
                    {text: 'Destination', label: "Destination", desc: 'Load Last Destination', type: 3},

                    // Customer/Organization // DBAName, MCNumber, USDOTNumber
                    {text: 'LegalName', label: "Legal name", desc: 'Organization', type: 4},
                    {text: 'DBAName', label: "DBA", desc: 'DBA name', type: 4},
                    {text: 'MCNumber', label: "MCN", desc: 'MC/FF/MX number', type: 4},
                    {text: 'USDOTNumber', label: "USDOT", desc: 'US DOT number', type: 4},

                    // Invoices DueDate Amount
                    {text: 'InvoiceID', label: "Invoice number", desc: 'Invoice number', type: 5},
                    {text: 'RefNumber', label: "Invoice reference", desc: 'Invoice reference', type: 5},
                    {text: 'DueDate', label: "Due date", desc: 'Invoice Due Date', type: 5},
                    {text: 'Amount', label: "Amount", desc: 'Invoice Amount', type: 5},

                    // Work Order
                    {text: 'WorkOrderNumber', label: "Work Order number", desc: 'Work Order number', type: 6},
                    {text: 'WorkOrderNumberCustomerReferenceNumber', label: "Work Order customer reference", desc: 'Work Order customer reference', type: 6},
                    {text: 'WorkOrderNumberTotalAmount', label: "Work Order total amount", desc: 'Work Order total amount', type: 6}
                ],
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {
                containerClass: 'col-md-4'
            }, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                containerClass: 'col-md-8 limit-field-container float-right'
            })
        }
    }

    handleToggleEditModal = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = (prefilled = '') => {
        this.setState({
            createModalOpen: !this.state.createModalOpen,
            prefilled: prefilled
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.fetchData()
        })
    }

    /** Render
     ================================================================= */
    render() {
        const {resource, translate} = this.props

        const data = getProp(resource, 'data.list', []);
        const count = getProp(resource, 'data.count', 0);
        const isLoading = resource?.isLoading;

        return (
            <React.Fragment>
                <Card>
                    <PageHeader
                        title={translate('text.email_templates')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleToggleCreateModal()}
                        buttonHidden={!checkPerm(this.getResourceName(), CREATE_PERM)}
                    />

                    <TableCard>
                        <TableFilters
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                        />

                        <ResourceTable
                            data={data}
                            count={count}
                            options={{
                                style: {horizontalLines: true, floatingActions: true},
                                behaviour: {hasVirtualRows: true}
                            }}
                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.queryFilterFields.limit.value}
                            offset={this.state.offset}
                            page={this.state.paginationPage}

                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onEdit={checkPerm(Resources.EmailTemplates, UPDATE_PERM) && this.handleToggleEditModal}
                            onDelete={checkPerm(Resources.EmailTemplates, DELETE_PERM) && this.handleToggleConfirmModal}
                        />

                        <TableCardFooter
                            show={!(!data.length && !isLoading) && count > this.state.queryFilterFields.limit.value}
                        >
                            <Pagination
                                count={count}
                                isLoading={isLoading}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                pageLimit={this.state.queryFilterFields.limit.value}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.props.tabResource, CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create new email template'}
                            btnLabel="Create email template"
                            onBtnClick={() => this.handleToggleCreateModal(this.state.queryFilterFields.query.value)}
                            filters={{}}
                        />
                    </TableCard>
                </Card>
                <ModalSaveResource
                    title={'Create email template'}
                    widthClass="max-w-xl"
                    show={this.state.createModalOpen}
                    onClose={() => this.handleToggleCreateModal()}
                    fields={this.getFields({EmailTemplateName: this.state.prefilled})}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName(),
                                errorMessage: true, successMessage: 'Email template created successfully!'
                            }))
                            this.handleToggleCreateModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata ?? {}}
                    handleInputChange={this.props.handleInputChange}
                />

                <ModalSaveResource
                    title={'Edit email template'}
                    widthClass="max-w-xl"
                    show={this.state.editModalOpen}
                    onClose={this.handleToggleEditModal}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()]
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName(),
                                errorMessage: true, successMessage: 'Email template edited successfully!'
                            }))
                            this.handleToggleEditModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata ?? {}}
                    handleInputChange={this.props.handleInputChange}
                />

                <ModalConfirm
                    title={'Confirm delete'}
                    show={!!this.state.confirmModalOpen}
                    text={'Are you sure you want to delete?'}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get('user'),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            piggyQuery: {id: this.props.id},
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName(),
                            errorMessage: true, successMessage: 'Email template deleted successfully!'
                        }))
                        this.handleToggleConfirmModal(false)
                    }}
                />
            </React.Fragment>
        )
    }
}
