import Layout from "../../../common/components/layout";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getUser} from "../../../common/util/util-auth";
import {Field, FieldsManager} from "../../../data/services/fields";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";
import ResourcePage from "../../../common/components/layout/layout-components/resource-page";
import Resources from "../../../data/services/resources";
import useQueryNew from "../../../hooks/use-query-new";
import {deleteResource, getResource, updateResource} from "../../../data/actions/resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {useLocation} from "react-router-dom";
import LocalStorage from "../../../common/util/localStorage";
import Modal from "../../../common/components/modal";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import {download} from "../../../data/actions/download";
import {currentDate} from "../../../common/util/util-dates";
import ModalHeader from "../../../common/components/modal/modal-header";
import OfficeCardDialog from "../../../common/components/modal/office-card";
import CreateOfficeDialog from "../../../common/components/layout/global-dialogs/create-update-office-dialog";
import {MapPinIcon} from "@heroicons/react/24/outline";
import {showMapDialog} from "../../../data/actions/ui";

export default function Offices({translate, match, history}) {
    /** Page constants
     ================================================================= */
    const pageTitle = translate('page.heading.offices');
    const tableKey = 'OfficeID';
    const noRecordsText = "Create a new office";
    const noRecordsButtonLabel = "Create office"
    const resourcePath = Resources.Offices;
    const tableDefaults = {
        behaviour: {
            rowSelect: true,
            canAdjustWidth: true,
            hasMenu: true
        },
        style: {
            condensed: true,
            isGPUAccelerated: true
        },
        version: "1" // changing version will force update tableDefaults settings
    }

    const location = useLocation();
    const pagePath = location.pathname.replaceAll("/", "");

    const user = getUser();
    const dispatch = useDispatch();
    const ui = useSelector(state => state.ui);
    const resource = useSelector(state => state.resource);

    const [breakpoint, setBreakpoint] = useState({});
    const [isResourceFormModalOpen, setIsResourceFormModalOpen] = useState(false);
    const [queryFields, setQueryFields] = useQueryNew(getQueryFields(), pagePath);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const [isConfirmRestoreModalOpen, setIsConfirmRestoreModalOpen] = useState(false);
    const [isResourceInfoModalOpen, setIsResourceInfoModalOpen] = useState(false);
    const query = FieldsManager.getFieldKeyValues(queryFields);

    const [selectedItem, setSelectedItem] = useState({});
    const [prefilled, setPrefilled] = useState("");

    /** Data Events
     ================================================================= */
    function fetchData() {
        dispatch(getResource({
            user: getUser(),
            resource: resourcePath,
            query: query
        }))
    }

    function downloadExcel() {
        dispatch(download({
            user: getUser(),
            resource: resourcePath,
            query: Object.assign({format: 'EXCEL', name: 'offices_' + currentDate() + '.xlsx'}, query)
        }))
    }

    /** UI Events
     ================================================================= */
    function handleBreakpointChange(breakpointUpdate) {
        setBreakpoint(Object.assign({}, breakpointUpdate));

        if (breakpointUpdate.index <= 1 && queryFields.limit.value !== 10) {
            handleQueryInputChange('limit', 10);
        }
    }

    function handleOpenResourceFormClick(item = null) {
        setIsResourceFormModalOpen(true);
        setSelectedItem(item);
    }

    function handleCloseResourceFormClick() {
        setIsResourceFormModalOpen(false);
        setSelectedItem(null);
        setPrefilled("");
    }

    function handleQueryInputChange(name, value) {
        const queryFieldsUpdate = FieldsManager.updateField(queryFields, name, value);

        if ("sortBy" === name) {
            if (value === queryFieldsUpdate?.sortBy?.value) {
                queryFieldsUpdate.sort.value = queryFieldsUpdate.sort.value === 'ASC' ? 'DESC' : 'ASC'
            }
        }

        setQueryFields(queryFieldsUpdate);
    }


    function handleClearAllFiltersClick() {
        setQueryFields(getQueryFields());
    }

    function handleViewResourceClick(item) {
        setIsResourceInfoModalOpen(true);
        setSelectedItem(item);
    }

    function handleDeleteResourceClick(item) {
        setSelectedItem(item);
        setIsConfirmDeleteModalOpen(true);
    }

    function handleConfirmDeleteClick() {
        dispatch(deleteResource({
            user: getUser(),
            query: Object.assign({
                OfficeID: selectedItem.OfficeID
            }, query),
            errorMessage: true,
            successMessage: `${selectedItem.OfficeName} archived`,
            resource: resourcePath,
            piggyResource: resourcePath
        }));
        setSelectedItem({});
        setIsConfirmDeleteModalOpen(false);
    }

    function handleRestoreResourceClick(item) {
        setSelectedItem(item);
        setIsConfirmRestoreModalOpen(true);
    }

    function handleConfirmRestoreClick() {
        dispatch(updateResource({
            user: getUser(),
            params: {
                OfficeID: selectedItem.OfficeID,
                ArchivedDate: 1
            },
            query: query,
            errorMessage: true,
            successMessage: `${selectedItem.OfficeName} restored`,
            resource: resourcePath,
            piggyResource: resourcePath
        }));
        setSelectedItem({});
        setIsConfirmRestoreModalOpen(false);
    }

    function handleLocationMapViewClick(item) {
        if ((item.Latitude === null || item.Latitude === '0') || item.Longitude === null || item.Longitude === '0') {
            return null;
        }

        dispatch(showMapDialog(item));
    }

    /** Helpers
     ================================================================= */
    function getQueryFields() {
        return {
            query: new Field('query', '', [''], false, 'search'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            sort: new Field('sort', 'DESC', ['']),
            sortBy: new Field('sortBy', tableKey, ['']),
            offset: new Field('offset', '', ['']),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    function getFields() {
        return {
            OfficeName: new Field('OfficeName', '', ['empty']),
            OfficeNumber: new Field('OfficeNumber', '', ['empty']),
            AddressName: new Field('AddressName', '', ['empty']),
            CityName: new Field('CityName', '', ['empty']),
            State: new Field('State', '', ['empty']),
            PhoneNumber: new Field('PhoneNumber', '', ['empty'], false, 'phone'),
            CreateUpdateDate: new Field('CreateUpdateDate', '', ['empty'], false, 'date'),
        }
    }

    useEffect(() => {
        fetchData();
    }, [queryFields]);

    useEffect(() => {
        // Todo: remove in the next version
        function consumeLegacyState() {
            const oldState = LocalStorage.get(pagePath + "_state");
            if (oldState) {
                const queryFieldsUpdate = Object.assign({}, queryFields);
                queryFieldsUpdate.sort.value = oldState.sort;
                queryFieldsUpdate.sortBy.value = oldState.sortBy;
                queryFieldsUpdate.limit.value = oldState.limit;
                queryFieldsUpdate.offset.value = oldState.offset;
                queryFieldsUpdate.query.value = oldState.queryFilterFields?.query?.value ?? "";
                queryFieldsUpdate.archived.value = oldState.queryFilterFields.archived?.value ?? "";

                LocalStorage.remove(pagePath + "_state");
                setQueryFields(queryFieldsUpdate);
            }
        }

        consumeLegacyState();
    }, [pagePath]);

    /** Render
     ================================================================= */
    return (
        <Layout
            ui={ui}
            user={user}
            match={match}
            history={history}
            location={location}
            translate={translate}
            dispatch={dispatch}
            isAccessible={!(resource.errorStatus === 2)}
            onBreakpointChange={handleBreakpointChange}
        >
            <ResourcePage
                tableKey={tableKey}
                pagePath={pagePath}
                resourcePath={resourcePath}
                afterPageTitle={(
                    <PageHeaderInfo
                        infoBox={(
                            <div>
                                <p>Offices aid in grouping.</p>
                                <p>Each office can be assigned to one division.</p>
                            </div>
                        )}
                        InfoBoxBtnLabel={translate('btn.read_more')}
                        dispatch={dispatch}
                    />
                )}
                pageTitle={pageTitle}
                tableFields={getFields()}
                onCreateResourceClick={handleOpenResourceFormClick}
                onNoRecordsCreateClick={() => {
                    setPrefilled(queryFields.query.value);
                    // setIsGroupFormModalOpen(true);
                }}
                queryFields={queryFields}
                onQueryInputChange={handleQueryInputChange}
                onClearAllFiltersClick={handleClearAllFiltersClick}
                fetchData={fetchData}
                translate={translate}
                breakpoint={breakpoint}
                onView={handleViewResourceClick}
                onEdit={handleOpenResourceFormClick}
                onDelete={handleDeleteResourceClick}
                onRestore={handleRestoreResourceClick}
                onRowClick={handleOpenResourceFormClick}
                tableDefaults={tableDefaults}
                noRecordsText={noRecordsText}
                noRecordsButtonLabel={noRecordsButtonLabel}
                onDownloadExcel={downloadExcel}
                actions={[
                    {
                        action: handleLocationMapViewClick,
                        icon: MapPinIcon,
                        visible: (it) => (it.Latitude && it.Longitude),
                        label: false, // make this a function
                        tooltip: translate('text.map'),
                    },
                ]}
            />

            <Modal
                show={isResourceFormModalOpen}
                widthClass={'max-w-5xl'}
                onClose={handleCloseResourceFormClick}
            >
                <CreateOfficeDialog
                    selectedItem={selectedItem}
                    prefilled={prefilled}
                    translate={translate}
                    close={handleCloseResourceFormClick}
                    query={query}
                />
            </Modal>

            <Modal
                show={isResourceInfoModalOpen}
                widthClass={"max-w-3xl"}
                translate={translate}
                onClose={() => setIsResourceInfoModalOpen(false)}
            >
                <ModalHeader
                    title={translate("text.office_info")}
                    onClose={() => setIsResourceInfoModalOpen(false)}
                />

                <OfficeCardDialog
                    onClose={() => setIsResourceInfoModalOpen(false)}
                    translate={translate}
                    office={selectedItem}
                />
            </Modal>

            <ModalConfirm
                title={translate("modal_heading.confirm_archive")}
                show={isConfirmDeleteModalOpen}
                text={`${translate('message.confirm_archive_generic')} ${selectedItem?.OfficeName}?`}
                onClose={() => {
                    setIsConfirmDeleteModalOpen(false);
                    setSelectedItem({});
                }}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={handleConfirmDeleteClick}
            />

            <ModalConfirm
                title={translate("modal_heading.confirm_restore")}
                show={isConfirmRestoreModalOpen}
                text={`${translate('message.are_you_sure_restore_office')} ${selectedItem?.OfficeName}?`}
                onClose={() => {
                    setIsConfirmRestoreModalOpen(false);
                    setSelectedItem(null);
                }}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={handleConfirmRestoreClick}
            />
        </Layout>
    )
}