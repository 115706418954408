import {useDispatch, useSelector} from "react-redux";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import {Field, FieldsManager} from "../../../data/services/fields";
import React, {useEffect, useState} from "react";
import {getResource} from "../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import useQuery from "../../../hooks/use-query";
import {
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getLookup,
    saveTableColumns
} from "../../../common/util/util-helpers";
import NoRecords from "../../../common/components/no-records-found/no-records";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import {checkPerm} from "../../../util/util";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY, READ_PERM} from "../../../common/util/util-consts";
import moment from "moment/moment";
import {formatMoney} from "../../../common/util/util-formaters";
import ModalDefault from "../../../common/components/modal/modal-default";
import InvoiceExpenseDetailsDialog from "../../../common/components/modal/invoice-expense-details-dialog";
import {getThirdResource} from "../../../data/actions/thirdResource";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import {cloneDeep} from "../../../common/util/util-vanilla";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import {DEFAULT_QUERY, UPDATE_PERM} from "../../../util/util-constants";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import CellLink from "../../../common/components/resource-table/table-components/cell-link";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import ResourceTableTags from "../../../common/components/resource-table/table-components/resource-table-tags";
import WorkOrderStatusBadge from "../../../common/components/badge/work-order-status-badge";
import {showGlobalModal} from "../../../data/actions/ui";
import InventoryReportDialog from "../../../common/components/modal/inventory-report-dialog";

export default function PartsReportTab({translate}) {
    const pagePath = "parts_report_tab";
    const tablePageDefaults = {
        behaviour: {
            canAdjustWidth: true
        },
        columns: {}
    }
    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector(state => state.resource);
    const thirdResource = useSelector(state => state.thirdResource);
    const dialogResource = useSelector(state => state.dialogResource);
    const isLoading = resource.isLoading;
    const data = resource?.data?.list ?? [];
    const count = resource?.data?.count ?? 0;
    const workOrderStatuses = getLookup("WorkOrderStatus") ?? {};

    const getSearchFields = () => {
        const {
            ProductServiceCategoryID,
            VendorID,
            ManufacturerID,
            ProductServicePartLocationID
        } = FieldsManager.getFieldKeyValues(queryFields)

        return {
            searchFields: JSON.stringify({
                ProductServiceCategoryID: ProductServiceCategoryID,
                VendorID: VendorID,
                ManufacturerID: ManufacturerID,
                ProductServicePartLocationID: ProductServicePartLocationID
            })
        }
    }

    /** State
     ================================================================= */
    const [tableOptions, setTableOptions] = useState(getDefaultTableOptions(getFields(), tablePageDefaults, pagePath, translate));
    const [selectedItem, setSelectedItem] = useState({});
    const [selectedInvoiceExpenseItem, setSelectedInvoiceExpenseItem] = useState({});
    const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
    const [isInvoiceExpenseDialogOpen, setIsInvoiceExpenseDialogOpen] = useState(false);
    const [queryFields, setQueryFields] = useQuery(getQueryFields(), pagePath)
    const query = Object.assign({}, FieldsManager.getFieldKeyValues(queryFields), getSearchFields());
    query.group = 1;
    const [isColumnsDialogVisible, setIsColumnsDialogVisible] = useState(false)
    const [isReservedDialogOpen, setIsReservedDialogOpen] = useState(false)
    const [reservedItem, setReservedItem] = useState(null)

    /** Data events
     ================================================================= */
    function fetchData() {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            query: query,
            resource: getResourceName()
        }))
    }

    /** UI events
     ================================================================= */
    function handleViewReportClick(selectedItem) {
        setSelectedItem(selectedItem);
        setIsReportDialogOpen(true);
    }

    function handleInvoiceExpenseClick(item = null) {
        setSelectedInvoiceExpenseItem(item);
        setIsInvoiceExpenseDialogOpen(true);
    }

    function handleOpenReservedDialog(item = null){
           setIsReservedDialogOpen(true);
           setReservedItem(item);
    }
    function handleQueryChange(name, value, resetOffset = false) {
        let queryFieldsUpdate = Object.assign({}, queryFields);
        queryFieldsUpdate = FieldsManager.updateField(queryFieldsUpdate, name, value);

        if (resetOffset) {
            queryFieldsUpdate.offset.value = 0;
        }

        setQueryFields(queryFieldsUpdate)
    }

    function handleClearFiltersClick(excludeAdditional = []) {
        const queryFieldsCLone = Object.assign({}, queryFields);
        const excludedFields = ['limit', 'offset', 'sort', 'sortBy'];

        Object.values(queryFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFieldsCLone, it.name, '')
        });

        setQueryFields(queryFieldsCLone);
    }

    function handleFilterInputChange(name, value) {
        setQueryFields(Object.assign({}, FieldsManager.updateField(queryFields, name, value)));
    }

    const setOptions = (options) => {
        setTableOptions(options)
        saveTableColumns(pagePath, options)
    }

    const setTableColumnOptions = (columns) => {
        let tableOptionsUpdate = cloneDeep(tableOptions)

        tableOptionsUpdate.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        setTableOptions(tableOptionsUpdate)
        setIsColumnsDialogVisible(false)
        saveTableColumns(pagePath, tableOptionsUpdate)
    }

    const getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(getFields(), {}, translate)
    }

    const handleViewTruckClick = (truckID) => {
        dispatch(showGlobalModal('ViewTruckCard', truckID));
    }

    const handleViewTrailerClick = (trailerID) => {
        dispatch(showGlobalModal('ViewTrailerCard', trailerID));
    }

    /** Helpers
     ================================================================= */
    function getFields() {
        return {
            ProductService: new Field('ProductService', '', [''], false, 'text', {
                omitSort: true
            }),
            SKU: new Field('SKU', '', [''], false, 'text', {
                omitSort: true
            }),
            OEMNumber: new Field('OEMNumber', '', [''], false, 'text', {
                omitSort: true
            }),
            CrossRefNumber: new Field('CrossRefNumber', '', [''], false, 'text', {
                omitSort: true
            }),
            ProductServiceCategoryID: new Field('ProductServiceCategoryID', '', ['empty'], false, 'select-search', {
                label: "ProductServiceCategory",
                omitSort: true
            }),
            ProductServicePartLocationID: new Field('ProductServicePartLocationID', '', [''], false, 'text', {
                label: "Location",
                omitSort: true
            }),
            ReorderPoint: new Field('ReorderPoint', '', [''], false, 'text', {
                label: "MinRecommendedStock",
                omitSort: true
            }),
            MaxRecommendedStock: new Field('MaxRecommendedStock', '', [''], false, 'text', {
                label: "MaxRecommendedStock",
                omitSort: true
            }),
            Reserved: new Field('Reserved', '', [''], false, 'custom', {
                omitSort: true,
                render: (it) => {
                    return (
                        <div>
                            {it.Reserved > 0 ?
                                (<CellButton
                                    onClick={() => checkPerm(Resources.WorkOrderProductServicesReport, READ_PERM) && handleOpenReservedDialog(it)}
                                >
                                    {it.Reserved}
                                </CellButton>) : it.Reserved
                            }
                        </div>
                    )
                }
            }),
            Qty: new Field('Qty', '', [''], false, 'text', {
                label: "current_stock",
                omitSort: true
            }),
            TotalTotalAmount: new Field('TotalTotalAmount', '', [''], false, 'money', {
                omitSort: true
            }),
        }
    }


    function getQueryFields() {
        return {
            // group: new Field('group', '1', [''], false, 'text', {}, {}),
            query: new Field('query', '', [''], false, 'search', {}, {}),
            sort: new Field('sort', 'ASC', [''], false, 'text', {}, {}),
            sortBy: new Field('sortBy', 'ProductService', [''], false, 'text', {}, {}),
            offset: new Field('offset', "0", [''], false, 'text', {}, {}),
            ProductServiceCategoryID: new Field('ProductServiceCategoryID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            VendorID: new Field('VendorID', '', [''], false, 'select-search', {
                label: 'recommendedVendor',
                addContainerClass: 'col-span-full'
            }, {
                isClearable: true
            }),
            ManufacturerID: new Field('ManufacturerID', '', [], false, 'select-search', {}, {
                isClearable: true
            }),
            ProductServicePartLocationID: new Field('ProductServicePartLocationID', '', [''], false, 'select-search', {}, {
                isClearable: true
            }),
            limit: new Field('limit', '10', [''], false, 'select', {
                labelType: 'float', hideLabel: true
            }, {menuPlacement: 'top'})
        }
    }

    function getSelects() {
        return {
            ManufacturerID: {
                api: 'api/' + Resources.WorkOrderProductsManufacturersQuick,
                query: DEFAULT_QUERY,
                searchMap: (item) => ({
                    value: item.ManufacturerID,
                    label: item.Manufacturer
                })
            },
            ProductServicePartLocationID: {
                api: 'api/' + Resources.WorkOrderPartLocationsQuick,
                query: DEFAULT_QUERY,
                searchMap: (item) => ({
                    label: item.ProductServiceLocation,
                    value: item.ProductServiceLocationID
                })
            },
            VendorID: {
                api: 'api/' + Resources.Vendors,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.LegalName + ", " + item.AddressName,
                    value: item.VendorID
                })
            }
        }
    }

    function getResourceName() {
        return Resources.WorkOrderProductServicesReport
    }

    useEffect(() => {
        fetchData();
    }, [queryFields]);

    /** Component Body
     ================================================================= */
    return (
        <>
            <div className={"flex ml-auto"}>
                <TableSettingsPopOver
                    options={tableOptions}
                    setOptions={setOptions}
                    toggleColumnSettingsDialog={() => setIsColumnsDialogVisible(true)}
                    translate={translate}
                />
            </div>

            <ActiveFilters
                onLabelClick={handleFilterInputChange}
                onClearFiltersClick={handleClearFiltersClick}
                filterFields={queryFields}
                handleQueryChange={handleQueryChange}
                translate={translate}
            />

            <TableCard>
                <TableFilters
                    hideLimit
                    filterFields={queryFields}
                    handleInputChange={handleQueryChange}
                    translate={translate}
                    exclu
                    largeGrid
                    forceDialog
                    isLoading={isLoading}
                    onRefreshTable={fetchData}
                    selects={getSelects()}
                />

                <ResourceTable

                    data={data}
                    fields={getFields()}
                    queryFields={queryFields}

                    onView={(item) => {
                        handleViewReportClick(item)
                    }}
                    translate={translate}
                    isLoading={isLoading}

                    saveTableOptions={setOptions}
                    options={tableOptions}
                />

                <NoRecords
                    show={(data.length === 0) && !isLoading}
                    title={translate('text.no_records')}
                    addClass={'pt-16 pb-10'}
                />


                <TableCardFooter
                    show={!(!data.length && !isLoading)}
                >
                    <Pagination
                        count={count}
                        isLoading={isLoading}
                        handleQueryChange={(name, value) => handleQueryChange(name, value)}
                        queryFields={queryFields}
                        translate={translate}
                    />
                </TableCardFooter>
            </TableCard>

            <InventoryReportDialog
                isReportDialogOpen={isReportDialogOpen}
                dialogResource={dialogResource}
                resource={Resources.WorkOrderProductServicesReport}
                dispatch={dispatch}
                thirdResource={thirdResource}
                translate={translate}
                setIsReportDialogOpen={setIsReportDialogOpen}
                selectedReportItem={selectedItem}
            />

            <TableOptionsDialog
                show={isColumnsDialogVisible}
                pagePath={pagePath}
                columns={tableOptions.columns}
                setTableColumnOptions={setTableColumnOptions}
                getDefaultTableColumnOptions={getDefaultTableColumnOptions}
                onClose={() => setIsColumnsDialogVisible(false)}
                translate={translate}
            />

            <ResourceTableDialog
                widthClass={'max-w-6xl'}
                show={isReservedDialogOpen}
                dialogResource={dialogResource}
                resource={Resources.WorkOrderProductServicesReport}
                title={'Work orders'}
                dispatch={dispatch}
                isFooterVisible={false}
                fields={{
                    AutoCounter: new Field('AutoCounter', '', [''], false, 'custom', {
                        render: (it) => {
                            return (
                                <CellLink
                                    to={checkPerm(Resources.WorkOrders, UPDATE_PERM) && "/work-orders/" + it.WorkOrderID}
                                    disabled={!checkPerm(Resources.WorkOrders, UPDATE_PERM)}
                                    openInNewTab={true}
                                >
                                    {it.AutoCounter}
                                </CellLink>
                            )
                        }
                    }),
                    WorkOrderStatusID: new Field('WorkOrderStatusID', '', [''], false, 'select', {
                        render: (it) => <WorkOrderStatusBadge
                            workOrderStatuses={workOrderStatuses}
                            statusID={it.WorkOrderStatusID}
                        />
                    }),
                    TruckID: new Field('TruckID', '', [''], false, 'select-search', {
                        render: (item) => {
                            if (!item) return null;

                            if (item.TruckID) {
                                return (
                                    <CellButton
                                        onClick={() => checkPerm(Resources.TrucksInfo, READ_PERM) && handleViewTruckClick(item.TruckID)}
                                    >
                                        {item.TruckNumber}
                                    </CellButton>
                                )
                            }
                        },
                        addContainerClass: "col-span-6 col-start-1"
                    }, {isClearable: true}),
                    TrailerID: new Field('TrailerID', '',  [''], false, 'select-search', {
                        render: (item) => {
                            if (!item) return null;

                            if (item.TrailerID) {
                                return (
                                    <CellButton
                                        onClick={() => checkPerm(Resources.Trailer, READ_PERM) && handleViewTrailerClick(item.TrailerID)}
                                    >
                                        {item.TrailerNumber}
                                    </CellButton>
                                )
                            }
                        },
                        addContainerClass: "col-span-6"
                    }, {isClearable: true}),
                    TotalAmount: new Field('TotalAmount', '', [''], false, 'money', {omitSort: true})
                }}

                tableKey="WorkOrderID"
                defaultQuery={{reserved: 1,id: reservedItem?.ProductServiceID ?? null}}
                onClose={() => {
                    setIsReservedDialogOpen(false);
                    setReservedItem(null);
                }}
                translate={translate}
                closeButtonLabel={translate("btn.close")}
            />
        </>
    )
}